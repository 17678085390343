.dashboard-portfolio-map {

}

/*.dashboard-portfolio-map .leaflet-container {
	width: 60vw;
	height: 60vw;
}
*/
.dashboard-portfolio-map .map-wrapper {
	padding: 20px;
	background-color: white;
	border-radius: 10px;
	display: inline-block;
}


/* desktop layout */
@media (min-width: 901px) {

	.dashboard-portfolio-map .leaflet-container {
		width: 500px;
		height: 500px;
	}
}

/* mobile layout */
@media (max-width: 900px) {

	.dashboard-portfolio-map .leaflet-container {
		width: calc(100vw - 100px);
		height: calc(100vw - 100px);
	}

}
