/*.left-nav { 
	height: calc(100% - 111px);
	position: fixed;
	width: 280px;
	background: white;
	border-right: 1px solid var(--border-color);
	overflow-y: auto;
	overflow-x: hidden;
	padding-top: 40px;
	padding-bottom: 40px;
}
*/
/*.left-nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.left-nav ul li {
	margin: 0;
	padding: 0;
}*/


/* category colors */
.collapsible-group-wrapper.category-violation,
span.data-set-nav-button.category-violation,
a.data-set-nav-button.category-violation,
button.data-set-nav-button.category-violation {
	background: rgba(241, 65, 108, 0.15);
}

.collapsible-group-wrapper.category-legals,
span.data-set-nav-button.category-legals,
a.data-set-nav-button.category-legals,
button.data-set-nav-button.category-legals {
	background: rgba(80, 205, 137, 0.15);
}

.collapsible-group-wrapper.category-application,
span.data-set-nav-button.category-application,
a.data-set-nav-button.category-application,
button.data-set-nav-button.category-application {
	background: rgba(0, 158, 247, 0.15);
}

.collapsible-group-wrapper.category-registration,
span.data-set-nav-button.category-registration,
a.data-set-nav-button.category-registration,
button.data-set-nav-button.category-registration {
/*	background: rgba(255, 199, 0, 0.15);*/
	background: rgba(114, 57, 234, 0.15);
}
/* end category colors */

span.data-set-nav-button,
a.data-set-nav-button,
button.data-set-nav-button {
	text-align: left;
	width: 100%;
	display: block;
	border: none;
	background-color: transparent;
	display: block;
	padding: 5px 20px 5px 20px;
	color: #212529;
	text-decoration: none;
}

.dark-mode span.data-set-nav-button,
.dark-mode a.data-set-nav-button,
.dark-mode button.data-set-nav-button {
	color: var(--dark-mode-color);
}

span.data-set-nav-button .dataset-title,
a.data-set-nav-button .dataset-title,
button.data-set-nav-button .dataset-title {
	max-width: 180px;
}

span.data-set-nav-button:hover {
	color: white;
	background-color: var(--bs-secondary);
}
span.data-set-nav-button {
	color: var(--bs-gray-600);
}

a.data-set-nav-button:hover,
button.data-set-nav-button:hover {
	border: none;
	/*background-color: #2c2e3e;*/
	background-color: var(--bs-primary);
	color: #fff;
	text-decoration: none;
}

.sidebar-collapse-container a.data-set-nav-button,
.sidebar-collapse-container button.data-set-nav-button {
	text-align: left;
	width: 100%;
	display: block;
	border: none;
	background-color: transparent;
	display: block;
	padding: 5px 20px 5px 50px;
	text-decoration: none;
}

.sidebar-collapse-container a.data-set-nav-button:hover,
.sidebar-collapse-container button.data-set-nav-button:hover {
	/*background-color: #2c2e3e;*/
	background-color: var(--bs-primary);
}

li.has-active-child .sidebar-collapse-button.is-collapsed,
.left-nav a.left-nav-button.is-active,
a.data-set-nav-button.is-active {
	background-color: #009EF7;	
	color: white;
}

.sidebar-collapse-button {
	padding: 5px 10px 5px 30px;
}

.sidebar-collapse-container a {
	display: block;
	padding: 5px 20px 5px 60px;
	text-decoration: none;
}

.sidebar-collapse-button:hover {
	/*background-color: #2c2e3e;*/
	background-color: var(--bs-primary);
	color: #fff;
}

li.divider {
	margin: 10px 20px 10px 30px;	
	border-bottom: 1px solid var(--border-color);
}

.address-search {
	margin: 0px 20px 20px 30px;
	width: auto;
}

.dataset-item-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	padding-right: 13px;
}

.sidebar-collapse-button .dataset-item-wrapper {
	padding-right: 9px;
	width: 100%;
}
