/*
background-color: var(--dark-mode-bg);
background-color: var(--dark-mode-bg-2);
*/

.rd-dashboard-nav {
/*	font-size: 30px;*/
	font-weight: bold;
	width: 220px;
/*	background-color: var(--rd-black);*/
	background-color: #fff;
	height: 100%;
	max-height: 400px;
	border-bottom-right-radius: 40px;
	padding-left: 10px;

	justify-content: space-between;
	display: flex;
	flex-direction: column;

	-webkit-transition: width 0.4s ease-in-out;
	-moz-transition: width 0.4s ease-in-out;
	-o-transition: width 0.4s ease-in-out;
	transition: width 0.4s ease-in-out;
}

.rd-dashboard-nav .rd-dashboard-nav-list {
	margin-bottom: 50px;
}

.rd-dashboard-nav .dashboard-nav-title-row {
	white-space: nowrap;
}

.rd-dashboard-nav .dashboard-nav-collapse-button-wrapper {
	display: flex;
	justify-content: right;
/*	margin: 20px;*/
}

.rd-dashboard-nav .dashboard-nav-collapse-button {
	font-size: 22px;
	margin-right: 5px;
}

.rd-dashboard-nav.rd-dashboard-collapsed {
	width: 50px;
}

/*.dark-mode .rd-dashboard-nav {
	background-color: var(--dark-mode-bg-2);
}*/

.rd-dashboard-nav .rd-dashboard-nav-title {
	font-size: 24px;
/*	padding: 40px 30px;*/
	padding: 20px;
/*	color: var(--rd-off-white);*/
}

.rd-dashboard-nav .rd-dashboard-nav-item {
	width: 100%;
/*	height: 63px;*/
	height: 36px;
	
	display: flex;
	justify-content: right;
}

.rd-dashboard-nav .rd-dashboard-nav-item.is-active .rd-dashboard-nav-link {
/*	background-color: var(--rd-grey);*/
/*	background-color: var(--light-bg-color);*/
	background-color: #ccc;
}
.dark-mode .rd-dashboard-nav .rd-dashboard-nav-item.is-active .rd-dashboard-nav-link {
/*	background-color: var(--rd-grey);*/
/*	background-color: var(--dark-mode-bg);*/
	background-color: #ccc;
}

.rd-dashboard-nav .rd-dashboard-nav-link {
/*	width: 100%;*/
	display: flex;
	justify-content: space-between;
/*	color: var(--rd-off-white);*/
	width: 245px;
	align-items: center;
	padding: 0 20px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}


.rd-dashboard-nav .rd-dashboard-nav-link:hover {
	text-decoration: none;
/*	color: var(--rd-green);*/
}

.rd-dashboard-nav .rd-dashboard-nav-icon {

}

.rd-dashboard-nav .rd-dashboard-nav-label {

}


/* desktop layout */
@media (min-width: 1231px) {
	.rd-dashboard-nav {
		
	}
}

/* mobile layout */
@media (max-width: 1230px) {
	.rd-dashboard-nav {
		
	}

}