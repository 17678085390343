.notificationAndSearch {
    display: flex;
    flex-direction: column;
    background-color: #181827;
    width: auto;
    /*box-shadow: 0 15px 11px 0 #a6a6a6;*/
    color: white;


    /*margin: 5% 5%;*/
}
.notificationAndSearch p{
    font-size: 1vmax;
    line-height: 2;
}
.notificationAndSearch h1 {
    font-weight: bold;
    font-size: 3vmax;
    width: 100%;
}

.notificationAndSearch h3 {
    font-size: 1.5vmax;
}


.title-container {
    display: flex;
    flex-direction: row;
    position: relative;
    width: fit-content;
    /*border: red 1px solid;*/
}

.title-container h1 {
    display: inline-block;
    /*border: 1px red solid;*/
    color: #28DF92;
}

.title-container img {
    position: absolute;
    top: -100%;
    left: 100%;


}

/* Top Section */

.nas-top {
    display: flex;
    flex-direction: row;
    /*border: blue 1px solid;*/

}

.nas-content{
    position: relative;
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: center;*/
    /*border: black 1px solid;*/
    padding: 20px;
}

.nas-content img {
    width: 100%;

    /*border: red 1px solid;*/
}



.nas-icon-container {
    display: flex;
    width: fit-content;
    justify-content: space-around;
    /*border: 1px red solid;*/
}

.nas-icon-container img {
    max-width: 35px;
}

.inverted{
    flex-direction: row-reverse;
}

.inverted .nas-content h3 {
    /*border: red solid 1px;*/
}
.nas-icon-container {
    width: 100%;
    /*border: blue solid 1px;*/
    justify-content: center;
}
.nas-icon-container a {
    margin: 2%;
}

.wifi {
    position: absolute;
    max-width: 5vmax;
    right: 10%;
    top: 25%;
}

@media screen and (max-width: 950px) {
    .nas-top {
        flex-direction: column;
        justify-content: center;
    }
    .title-container {
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-top: 5%;
    }
    .title-container h1 {
        font-size: 1.75rem;
    }
    .nas-content {
        margin-top: 5%;
    }
    .nas-content p {
        font-size: 1rem;
    }
    .title-container img {
        /*display: none;*/
        top: -80%;
    }
    .inverted .nas-content  {
        /*border: 2px green solid;*/
        align-items: end;
        padding: 20px;
    }
    .inverted .nas-content p {
        text-align: right;
    }
    .inverted .nas-content h3 {
        text-align: center;
        width: 100%;
        padding-top: 5%;
        font-size: 1.25rem;
    }
    .notificationAndSearch h3 {
        font-size: 1.25rem;
    }

}
